<template>
  <svg :width=" width ? width : 40" :height=" height ? height : 40" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#plusIcon)">
      <circle cx="35" cy="35" :r="width ? width - 5: 35" :fill="fill || '#8BD118'"/>
      <line  x1="15" y1="35" x2="55" y2="35" stroke-width="5" stroke="white"/>
      <line  x1="35" y1="15" x2="35" y2="55" stroke-width="5" stroke="white"/>
    </g>

    <defs>
      <filter id="plusIcon" x="0" y="0" width="70" height="70" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="5"/>
        <feGaussianBlur stdDeviation="7.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.458824 0 0 0 0 0.458824 0 0 0 0 0.458824 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>

</template>

<script>
    import icon_config from "@/mixins/icon_config";

    export default {
        name: "IconPlus",
        mixins: [icon_config],
      /*computed: {
          calculationLineHeightStart () {

          },
          calculationLineHeightEnd () {

          }
      }*/
    }
</script>

<style lang="sass" scoped>
.add__icon
  margin: 5px 10px 5px 0
</style>
