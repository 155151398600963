import { render, staticRenderFns } from "./IconTable.vue?vue&type=template&id=3aa0da78&scoped=true"
import script from "./IconTable.vue?vue&type=script&lang=js"
export * from "./IconTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aa0da78",
  null
  
)

export default component.exports