<template>
  <div class="wb-bange">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppBange'
}
</script>

<style lang="sass" scoped>
  .wb-bange
    @include fontStyle(800, 16px)
    display: flex
    align-items: center
    justify-content: center
    margin-left: auto
    background-color: rgba(0, 0, 0, 0.07)
    width: 46px
    height: 32px
    border-radius: 6px
</style>
