<template>
  <!-- <div> -->
  <AppLoader
    v-if="showLoad"
    class="full-height"
  />
  <div v-else>
    <table
      class="table"
      cellspacing="0"
    >
      <thead>
        <tr>
          <th class="table-left-margin" />
          <th
            v-if="isEdit"
            class="table__header table-combobox"
          >
            <AppCombobox
              v-model="selectedAll"
              class="table__checkbox__wrapper"
              class-checkbox="table__checkbox"
              @change="allChange"
            />
          </th>

          <th
            v-for="key in keys"
            :key="key.value"
            :class="{
              'table__header_align_right': key.alignRight
            }"
            class="table__header"
          >
            {{ key.label }}
            <IconArrowDown v-if="key.config.canSort" />
          </th>

          <th class="table-right-margin">
          &nbsp;
          </th>
        </tr>
      </thead>
      <tbody v-if="array.length">
        <tr
          v-for="(item, index) in array"
          :key="index"
          ref="list"
          :class="trClassItem"
          @click="$emit('click', item)"
        >
          <td class="table-left-margin">
          &nbsp;
          </td>
          <td v-if="isEdit">
            <AppCombobox
              class="table__checkbox__wrapper"
              class-checkbox="table__checkbox"
              :checked="checkedItem.includes(item.id)"
              @change="selectItem(item)"
            />
          </td>
          <td
            v-for="key in keys"
            :key="key.value"
            class="table__item"
          >
            <slot
              :item="item"
              :name="key.value"
            >
              {{ key.valueProp ? getValueByKey(item, key) : keyToValue(item, key.value) }}
            </slot>
          </td>
          <td class="table-right-margin">
          &nbsp;
          </td>
        </tr>
        <tr v-if="showReduceArray">
          <td class="table-left-margin">
          &nbsp;
          </td>
          <td
            v-for="key in keys"
            :key="key.value"
            class="table__item table-summ"
          >
            <slot
              :item="reduceArrayVal"
              :name="'result_'+ key.value"
            >
              {{ key.valueProp ? getValueByKey(reduceArrayVal, key) : keyToValue(reduceArrayVal, key.value) }}
            </slot>
          </td>
          <td class="table-right-margin">
          &nbsp;
          </td>
        </tr>
      </tbody>
    </table>
    <AppLoadMore />
  </div>
  <!--  <div-->
  <!--    v-else-->
  <!--    class="empty-list mx-auto mt-4"-->
  <!--  >-->
  <!--    <span>-->
  <!--      <slot name="empty">-->
  <!--        {{ $t('empty_list') }}-->
  <!--      </slot>-->
  <!--    </span>-->
  <!--  </div>-->
  <!-- </div> -->
</template>

<script>
import userUtil from '@/utils/user-util'
import IconArrowDown from '@/components/common/icons/IconArrowDown'
import AppCombobox from '@/components/common/simple/AppCombobox'
import AppLoader from '@/components/AppLoader'
import { scrollIsBottom } from '@/utils/scroll_utils'
import AppLoadMore from '@/components/common/simple/AppLoadMore'

export default {
  name: 'AppTable',
  components: { AppLoader, AppCombobox, IconArrowDown, AppLoadMore },
  props: {
    keys: {
      type: Array
    },
    array: {
      type: Array
    },
    trClassItem: {
      type: [Array, String, Object]
    },
    isEdit: {
      type: Boolean
    },
    checkedCallBack: {
      type: Function,
      required: false
    },
    valueProp: {
      type: String,
      required: false,
    },
    showReduceArray: {
      type: Boolean,
      required: false,
      default: false
    },
    needCalculateReduceArray: {
      type: Boolean,
      required: false,
      default: false
    },
    checkedItem: {
      type: Array,
      default: () => []
    },
    event: {
      type: Function
    },
    isShowLoad: {
      type: Boolean,
      default: false
    },
    defaultSize: {
      type: Number,
      default: 20
    }
  },
  data () {
    return {
      selectedAll: false,
      showLoad: false,
      page: 1,
    }
  },

  computed: {
    reduceArrayVal () {
      if ((!this.needCalculateReduceArray || !this.showReduceArray) || this.array.length === 0) return {}

      return this.array.reduce((previousValue, currentValue) => {
        const result = previousValue
        this.keys.forEach((key) => {
          const resultValue = result[key.value] ? result[key.value]
            : !isNaN(parseInt(currentValue[key.value])) ? 0 : ''

          result[key.value] = resultValue + currentValue[key.value]
        })
        return result
      }, {})
    },
    pageHasOfficeSelect () {
      return this.$route.meta.hasOfficeSelect
    },
    officeSelected () {
      return this.$store.state.office.userOffice || null
    },
    user () {
      return this.$store.state.users.info
    }
  },

  watch: {
    // selectedAll (val) {
    //   this.$emit('selectAll', val)
    // },
    isShowLoad (val) {
      this.showLoad = val
    },
    '$route.params.id': function (officeId) {
      if (!officeId) {
        return
      }
      if (this.event && this.pageHasOfficeSelect && userUtil.isRoot(this.user)) {
        this.showLoad = true
        this.page = 1
        this.$store.dispatch('clearTableItems')
        this.event().finally(() => {
          this.page++
          this.showLoad = false
        })
      }
    }
  },
  beforeUpdate () {
    this.showLoad = this.isShowLoad
  },
  created () {
    if (this.officeSelected || !this.pageHasOfficeSelect || !userUtil.isRoot(this.user)) {
      this.showLoad = this.isShowLoad

      // if (this.event && !this.array.length) {
      if (this.event) {
        this.event().finally(() => {
          this.showLoad = false
        })
      } else {
        this.showLoad = this.isShowLoad
      }
    }
  },
  mounted () {
    // document.querySelector('.wb-main-wrapper').addEventListener('scroll', this.loadPagination)
    // this.$once('hook:beforeDestroy', () => {
    //   document.querySelector('.wb-main-wrapper').removeEventListener('scroll', this.loadPagination)
    // })
  },
  methods: {
    comboChecked (item) {
      if (this.checkedCallBack) {
        this.checkedCallBack(item)
      }
      return this.checkedItem.includes(item.id)
    },
    loadPagination (event) {
      const { list } = this.$refs
      if (!list) return
      if (this.event && scrollIsBottom(event.target)) {
        this.event(this.page, this.defaultSize)
          .then(() => {
            this.page++
          })
      }
    },
    getValueByKey (item, key) {
      return item[key.value] && item[key.value][key.valueProp] ? item[key.value][key.valueProp] : ''
    },
    keyToValue (item, key) {
      if (key === 'regionObject') {
        return item.regionSetting && item.regionSetting[0] && item.regionSetting[0].region.title
      }
      if (key === 'payPerHour') {
        return item.regionSetting && item.regionSetting[0] && item.regionSetting[0].payPerHour
      }
      if (key === 'payMax') {
        return item.regionSetting && item.regionSetting[0] && item.regionSetting[0].maxPay
      }

      let value = ''
      const trees = key.split('.')
      trees.forEach((treesKey) => {
        if (typeof value === 'object') {
          value = value ? value[treesKey] : null
        } else {
          value = item[treesKey]
        }
      })
      return value
    },
    selectItem (item) {
      this.selectedAll = false
      this.$emit('selectCombobox', item)
    },
    allChange ($event) {
      this.$emit('selectAll', $event)
    },
  }
}
</script>

<style lang="sass" scoped>
  .table
    width: 100%
    border: 1.5px solid rgba(0, 0, 0, 0.1)
    box-sizing: border-box
    box-shadow: 0px 5px 15px rgba(117, 117, 117, 0.1)
    border-radius: 6px
    background-color: white
    cursor: pointer
    &__header
      @extend %table_header
      color: rgba(0, 0, 0, 0.31)
      border-bottom: 1px solid #EDEDED
      padding: 15px 0 10px 0
      text-align: left

      &_align_right
        text-align: right

    &__item
      font-size: 12px
      border-bottom: 1px solid #EDEDED
      padding: 10px 0 10px 0

  .offer__table,
  .vacancies__table
    margin-top: 20px

  .table-left-margin, .table-right-margin
    width: 20px
    height: 45px

  .table-combobox
    padding-right: 10px
    height: 22px
    width: 22px


  .empty-list
    display: flex
    justify-content: center
    align-items: center
    flex-grow: 1
    margin-top: 30px
    span
      @include fontStyle(800, 18px)

  .table-summ
    border-top: $default_border_bottom
    border-color: rgba(0, 0, 0, 0.4)
    border-bottom: 0
</style>
