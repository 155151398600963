<template>
  <v-card
    style="width: 320px; position: relative"
    elevation="8"
    class="px-4 my-3 mx-2 justify-start"
    :class="{'offer-selected': isSelected, 'edit-mode' : isEditMode}"
    @click.native="clickToOffer"
  >
    <v-btn
      v-if="isSelected"
      style="position: absolute; top: 45%; left: 45%; z-index: 999999"
      x-small
      color="success"
      fab
    >
      <v-icon>mdi-check-circle</v-icon>
    </v-btn>
    <v-card-actions class="py-0 my-0 mx-0 px-0">
      <span
        style="
        display: inline-block;
        width: 24px; height: 24px;
        border-radius: 9px;
        text-align: center;
        line-height: 24px;
        font-size: small;
"
        class="success-color mr-2"
      >
        {{ offer.childrenNumber }}
      </span>
      <span class="success-color">
        {{ `${offer.executorAssignmentType ? offer.executorAssignmentType.label: ''}` }}
      </span>
      <v-spacer />
      <v-badge
        v-if="offer.unreadMessageNumber"
        color="error"
        class="success-color"
        :content="`${offer.unreadMessageNumber || 0}`"
        overlap
        left
      >
        <v-icon class="success-color ml-1">
          mdi-chat
        </v-icon>
      </v-badge>
      <span class="red--text" />
    </v-card-actions>
    <v-card-actions
      class="py-0 my-0 px-0"
      style="position:relative;"
    >
      <v-icon class="success-color mr-1">
        mdi-account-circle
      </v-icon>
      <span
        class="d-inline-block text-truncate"
        style="font-size: large"
      >
        {{ offer.vacancy.localeTitle ? offer.vacancy.localeTitle : offer.vacancy.title }}
      </span>
      <v-icon
        v-if="isMulti"
        class="success-color ml-1"
      >
        mdi-calendar-month
      </v-icon>
    </v-card-actions>
    <v-card-text class="pt-0 px-0">
      <v-row
        v-if="isMulti"
        no-gutters
        class="py-0 my-0"
      >
        <v-col
          cols="12"
          :md="6"
          :sm="6"
          class="py-0 my-0"
        >
          <v-card-actions class="py-0 my-0 mx-0">
            <v-icon
              x-large
              color="warning"
            >
              mdi-alert-circle-outline
            </v-icon>
            <span style="font-size: 0.9em; color: rgba(0,0,0,0.6)">
              {{ `Мульти- ` }}
              {{ `оффер` }}
            </span>
          </v-card-actions>
        </v-col>
        <v-col
          cols="12"
          :md="6"
          :sm="6"
          class="py-0 my-0"
        >
          <v-card-actions
            class="py-0 my-0"
            style="min-width: 100px"
          >
            <span style="font-size: 0.9em; color: rgba(0,0,0,0.6)">
              {{ `${offer.startDate} ` }}
              {{ `${offer.endDate}` }}
            </span>
          </v-card-actions>
        </v-col>
      </v-row>
      <v-divider class="my-2" />
      <v-row
        no-gutters
        class="py-0 my-0 px-0"
      >
        <v-col
          cols="12"
          :md="8"
          :sm="8"
          class="py-0 my-0"
        >
          <AppTextDateTime class="grid__item__date">
            <template #date>
              {{ $t('date.start') }}: {{ isMulti ? '' : offer.startDate }}
            </template>
            <template #time>
              {{ offer.startTime }}
            </template>
          </AppTextDateTime>
          <AppTextDateTime class="grid__item__date">
            <template #date>
              {{ $t('date.end') }}: {{ isMulti ? '' :offer.endDate }}
            </template>
            <template #time>
              {{ offer.endTime }}
            </template>
          </AppTextDateTime>
        </v-col>
        <v-col
          cols="12"
          :md="4"
          :sm="4"
          class="py-0 my-0"
        >
          <AppBange>{{ offer.duration }}</AppBange>
        </v-col>
      </v-row>
      <v-divider class="my-2" />
      <v-row
        no-gutters
        class="py-0 my-0"
      >
        <v-col
          cols="12"
          :md="6"
          :sm="6"
          class="py-0 my-0"
        >
          <span class="offers__grid__item__info__text">
            {{ $t('offerList.card.responses') }}
            <span class="offers__grid__item__info__text__count">{{ offer.respondNumber || '' }}</span>
          </span>
        </v-col>
        <v-col
          cols="12"
          :md="6"
          :sm="6"
          class="py-0 my-0"
        >
          <span class="offers__grid__item__info__text">
            {{ $t('offerList.card.invite') }}
            <span class="offers__grid__item__info__text__count">{{ offer.invitationNumber || '' }}</span>
          </span>
        </v-col>
      </v-row>
      <v-divider class="my-2" />
      <v-card-actions class="py-0 my-0 px-0">
        <span
          class="offers__grid__item__bottom__visit"
          style="font-size: small"
        >
          {{ offer.supportNumber || '' }}
        </span>
        <v-spacer />
        <span class="offers__grid__item__bottom__visit">
          {{ offer.viewNumber || 0 }}
          <IconEye />
        </span>
      </v-card-actions>
    </v-card-text>
  </v-card>
  <!--    <app-grid-item-->
  <!--      style="cursor: pointer; position: relative"-->
  <!--      class="offers__grid__item"-->
  <!--      :item="offer"-->
  <!--      @click="clickToOffer"-->
  <!--    >-->
  <!--      <div-->
  <!--        class="offers__grid__item__header"-->
  <!--        style=" position: relative; padding-bottom: 40px"-->
  <!--      >-->
  <!--        <div-->
  <!--          v-if="offer.unreadMessageNumber"-->
  <!--          class="unread-message-badge"-->
  <!--        >-->
  <!--          {{ offer.unreadMessageNumber }}-->
  <!--        </div>-->
  <!--        <span style="position: relative">-->
  <!--          <IconEmployee />-->
  <!--          <sup-->
  <!--            style="-->
  <!--          position: absolute;top: -15px;-->
  <!--          color: rgb(139, 209, 24);-->
  <!--          border-radius: 50%;-->
  <!--          min-width: 180px;-->
  <!--          left: 8px;-->
  <!--          font-size: large;-->
  <!--          font-weight: bold;"-->
  <!--          > {{-->
  <!--              `${offer.childrenNumber || ''}` }}-->
  <!--            <span-->
  <!--              class="ml-4"-->
  <!--              style="font-size: small"-->
  <!--            >-->
  <!--              {{ `${offer.executorAssignmentType ? offer.executorAssignmentType.label: ''}` }}-->
  <!--            </span>-->
  <!--          </sup>-->
  <!--        </span>-->
  <!--        <span class="offers__grid__item__header__title">-->
  <!--          {{ offer.vacancy.localeTitle ? offer.vacancy.localeTitle : offer.vacancy.title }}-->
  <!--        </span>-->
  <!--        <IconOfferMulti-->
  <!--          v-if="isMulti"-->
  <!--          :width="24"-->
  <!--          :height="24"-->
  <!--        />-->
  <!--        <div-->
  <!--          v-if="isMulti"-->
  <!--          style="position: absolute;bottom: 5px; left: 0; right: 0;"-->
  <!--        >-->
  <!--          <div style="display: flex; flex-direction: row; justify-content:
     space-between; align-items: center">-->
  <!--            <div style="border: 1px solid #FAC300; width: 24px; height:
     24px; border-radius: 50%; text-align: center">-->
  <!--              <IconAttention-->
  <!--                style="margin-top: 4px"-->
  <!--                :width="16"-->
  <!--                :height="16"-->
  <!--              />-->
  <!--            </div>-->
  <!--            <span style="font-size: 0.9em">Мульти-оффер:</span>-->
  <!--            <span style="font-size: 0.8em; color: rgba(0,0,0,0.6)">{{ `${offer.startDate}
    - ${offer.endDate}` }}</span>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      <div class="offers__grid__item__period">-->
  <!--        <div class="offers__grid__item__period__date">-->
  <!--          <AppTextDateTime class="grid__item__date">-->
  <!--            <template #date>-->
  <!--              {{ $t('date.start') }}: {{ isMulti ? '' : offer.startDate }}-->
  <!--            </template>-->
  <!--            <template #time>-->
  <!--              {{ offer.startTime }}-->
  <!--            </template>-->
  <!--          </AppTextDateTime>-->

  <!--          <AppTextDateTime class="grid__item__date">-->
  <!--            <template #date>-->
  <!--              {{ $t('date.end') }}: {{ isMulti ? '' :offer.endDate }}-->
  <!--            </template>-->
  <!--            <template #time>-->
  <!--              {{ offer.endTime }}-->
  <!--            </template>-->
  <!--          </AppTextDateTime>-->
  <!--        </div>-->

  <!--        <AppBange>{{ offer.duration }}</AppBange>-->
  <!--      </div>-->

  <!--      <div class="offers__grid__item__info">-->
  <!--        <span class="offers__grid__item__info__text">-->
  <!--          {{ $t('offerList.card.responses') }}-->
  <!--          <span class="offers__grid__item__info__text__count">{{ offer.respondNumber || '' }}</span>-->
  <!--        </span>-->
  <!--        <span class="offers__grid__item__info__text">-->
  <!--          {{ $t('offerList.card.invite') }}-->
  <!--          <span class="offers__grid__item__info__text__count">{{ offer.invitationNumber || '' }}</span>-->
  <!--        </span>-->
  <!--      </div>-->

  <!--      <v-card-actions-->
  <!--        class="py-3"-->
  <!--      >-->
  <!--        <span-->
  <!--          class="offers__grid__item__bottom__visit"-->
  <!--          style="font-size: small"-->
  <!--        >-->
  <!--          {{ offer.supportNumber || '' }}-->
  <!--        </span>-->
  <!--        <v-spacer />-->
  <!--        <span class="offers__grid__item__bottom__visit">-->
  <!--          {{ offer.viewNumber || 0 }}-->
  <!--          <IconEye />-->
  <!--        </span>-->
  <!--      </v-card-actions>-->
  <!--      &lt;!&ndash;    <div&ndash;&gt;-->
  <!--      &lt;!&ndash;      v-if="!isMulti"&ndash;&gt;-->
  <!--      &lt;!&ndash;      class="offers__grid__item__bottom"&ndash;&gt;-->
  <!--      &lt;!&ndash;    >&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;      <AppTextMoney&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;        :money="parseFloat(parseFloat(offer.expense *-->
  <!--      offer.childrenNumber).toFixed(2))"&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;        class="price__text_bold"&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;        style="margin-left: 0"&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;      />&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;      <AppTextMoney&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;        :money="offer.tax"&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;        class="price__text_bold"&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;        style="color: rgba(0,0,0,0.3)"&ndash;&gt;-->
  <!--      &lt;!&ndash;      />&ndash;&gt;-->
  <!--      &lt;!&ndash;      <span class="offers__grid__item__bottom__price-full">-->
  <!--              {{ parseInt(offer.expense) | numberToBeautifulNumber }}-->
  <!--            </span>&ndash;&gt;-->
  <!--      &lt;!&ndash;      <span class="offers__grid__item__bottom__price">-->
  <!--              {{ parseInt(offer.expense) | numberToBeautifulNumber }}-->
  <!--             </span>&ndash;&gt;-->
  <!--      &lt;!&ndash;      <span class="offers__grid__item__bottom__visit">&ndash;&gt;-->
  <!--      &lt;!&ndash;        {{ offer.viewNumber || '' }}&ndash;&gt;-->
  <!--      &lt;!&ndash;        <IconEye />&ndash;&gt;-->
  <!--      &lt;!&ndash;      </span>&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;      <span&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;        class="offers__grid__item__bottom__email"&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;        :data-no-read="offer.unreadMessageNumber"&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;      >&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;
      <IconEmail v-if="!offer.unreadMessageNumber" />&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;        <IconEmailActive v-else />&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;      &lt;!&ndash;      </span>&ndash;&gt;&ndash;&gt;-->
  <!--      &lt;!&ndash;    </div>&ndash;&gt;-->

  <!--      <IconComplete-->
  <!--        v-if="isSelected"-->
  <!--        class="offers__grid__item__selected"-->
  <!--      />-->
  <!--    </app-grid-item>-->
</template>

<script>
// import AppGridItem from '@/components/common/AppGridItem'
// import IconEmployee from '@/components/common/icons/IconEmployee'
// import IconAttention from '@/components/common/icons/IconAttention'
// import IconOfferMulti from '@/components/common/icons/IconOfferMulti'
// import IconComplete from '@/components/common/icons/IconComplete'
// import IconEmailActive from '@/components/common/icons/IconEmailActive'
// import IconEmail from '@/components/common/icons/IconEmail'
import AppTextDateTime from '@/components/common/AppTextDateTime'
import AppBange from '@/components/common/AppBange'
import taxMixin from '@/mixins/taxMixin.vue'
import { dateFormatApi, msToTime } from '@/utils/date_utils'
import IconEye from '@/components/common/icons/IconEye'
export default {
  name: 'OfferGridItem',
  components: {
    IconEye,
    AppBange,
    AppTextDateTime,
    // IconOfferMulti,
    // IconAttention,
    // IconComplete,
    // IconEmployee,
    // AppGridItem
  },
  mixins: [taxMixin],
  props: {
    offer: {
      type: Object,
      required: true
    },
    isEditMode: {
      type: Boolean,
    },
    selectedOffer: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      startDate: null,
      endDate: null,
    }
  },
  computed: {
    isMulti () {
      return !!this.offer.isScheduler && this.offer.dayNumber && parseInt(`${this.offer.dayNumber}`) > 1
    },
    isSelected () {
      return this.selectedOffer.includes(this.offer.id)
    }
  },
  created () {
    this.startDate = dateFormatApi(this.offer.startDate, this.offer.startTime)
    this.endDate = dateFormatApi(this.offer.endDate, this.offer.endTime)
  },
  methods: {
    parseTime (millisecond) {
      return msToTime(millisecond, { hourShow: true })
    },
    clickToOffer () {
      this.$emit('click', this.offer)
    }
  }
}
</script>

<style lang="sass" scoped>
  $default_padding-bottom: 10px

  .success-color
    color: #8bd118
  .edit-mode
    border: 1px solid #8bd118
  .offer-selected
    opacity: 0.5
    border: 1px solid #8bd118
  .grid-shadow
    -webkit-box-shadow: 8px -8px 8px 4px #7A7A7A
    -moz-box-shadow: 8px -8px 8px 4px #7A7A7A
    box-shadow: 8px -8px 8px 4px #7A7A7A

  .offers__grid__item
    &__selected
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      z-index: 1000
    &__header
      display: flex
      align-items: center
      padding-bottom: 15px
      border-bottom: $default_border_bottom

      &__icon
        flex-shrink: 0

      &__title
        @extend %header-2-type
        white-space: nowrap
        text-overflow: ellipsis
        margin-left: 10px
        overflow: hidden

    &__period
      margin-top: $default_padding-bottom
      display: flex
      padding-bottom: $default_padding-bottom
      border-bottom: $default_border_bottom

      &__date
        display: flex
        flex-direction: column

      &__date__text
        @extend %date-text
        color: rgba(0, 0, 0, 0.6)
        &:first-of-type
          margin-bottom: 8px
      &__time__text
        @extend %date-text
        font-weight: bold
      &__time
        display: flex
        align-items: center
        justify-content: center
        margin-left: auto
        background-color: rgba(0, 0, 0, 0.07)
        width: 62px
        height: 42px
        font-family: $mainFont
        font-style: normal
        font-weight: 800
        font-size: 20px
        line-height: 24px
        text-align: center
        border-radius: 6px

    &__info
      display: flex
      margin-top: $default_padding-bottom
      padding-bottom: $default_padding-bottom
      border-bottom: $default_border_bottom
      &__text
        @extend %navigation__children-item
        color: rgba(0, 0, 0, 0.8)
        &:last-of-type
          margin-left: auto
        &__count
          color: #A7A7A7
          @extend %navigation__children-item

    &__bottom
      display: flex
      margin-top: 10px

      &__price-full
        @extend %input-text
        color: rgba(0, 0, 0, 0.91)
        margin-right: 20px
        font-weight: 800
      &__price
        @extend %input-text
        color: rgba(0, 0, 0, 0.2)
        font-weight: 800
      &__visit
        font-style: normal
        font-weight: 500
        font-size: 14px
        line-height: 17px
        margin-left: auto
        color: rgba(0, 0, 0, 0.21)
      &__email
        position: relative
        margin-left: 10px

</style>
