<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('click')">
    <g opacity="0.6">
      <path d="M4.37585 0L15.1248 10.7489L10.749 15.1247L0 4.3758L4.37585 0Z" fill="black"/>
      <path d="M16.0001 12V16H12.0001L16.0001 12Z" fill="black"/>
    </g>
  </svg>
</template>

<script>
    export default {
        name: "IconPanEdit"
    }
</script>

<style scoped>

</style>
