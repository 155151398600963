<template>
  <div class="offer__filter-top">
    <div
      class="filter-top__element filter-button"
      @click="createOffer"
    >
      <IconPlus style="width: 32px;" />
    </div>

    <div class="filter-top__left-group">
      <span
        v-for="item in statusGroups"
        :key="item.value"
        class="filter-top__element__text"
        :class="{'filter-top__element__text_active': filters.statusGroup === item.value}"
        @click="changeStatus(item.value)"
      >{{ item.label }} {{ office && office[item.count] >= 0 ? `(${office[item.count]})` : '' }}</span>
    </div>

    <div class="filter-top__right-group">
      <span
        v-for="item in statusOwners"
        :key="item.value"
        class="filter-top__element__text"
        :class="{'filter-top__element__text_active': filters.statusOwners === item.value}"
        @click="changeOffer(item.value)"
      >{{ item.label }}</span>
    </div>

    <div
      v-if="!isEdit"
      class="filter-top__element"
      @click="$emit('edit')"
    >
      <IconPanEdit />
      <span class="filter-top__element__element-text office__text-management">
        {{ $t('offerList.header.button.control') }}
      </span>
    </div>

    <div
      v-else
      class="filter-top__element"
    >
      <span
        class="filter-top__element__element-text office__text-management"
        @click="$emit('edit')"
      >
        {{ $t('offerList.header.button.accept') }}
      </span>
      <span
        class="filter-top__element__element-text office__text-management filter-top__element__element-text_warm"
        :class="{'filter-top__element__element-text_disabled': selectedOffer.length === 0}"
        @click="cancelOffer"
      >
        {{ $t('offerList.header.button.cancel_offer') }}
      </span>
    </div>

    <component
      :is="layout.label"
      v-for="layout in layouts"
      :key="layout.value"
      class="filter-top__element__icon"
      :class="layout.class"
      :isActive="currentLayout === layout.value"
      @click="$emit('changeLayout', layout.value)"
    />
  </div>
</template>

<script>
import OfferFilterBar from '@/components/offer/OfferFilterBar'
import IconPlus from '@/components/common/icons/IconPlus'
import IconPanEdit from '@/components/common/icons/IconPanEdit'


export default {
  name: 'OfferFilterBarHeader',
  components: { IconPlus, IconPanEdit },
  mixins: [OfferFilterBar],
  computed: {
    office () {
      return this.$store.state.office.officeUser
    }
  }
}
</script>

<style lang="sass" scoped>
  .offer__filter-top
    display: flex
    align-items: center
    height: 45px

  .filter-top, .filter-bottom

    &__left-group, &__right-group
      margin-right: 30px

</style>
