<template>
  <div class="offer__filter">
    <div class="filter-top">
      <div class="filter-top__left-group">
        <div
          class="filter-top__element filter-button"
          @click="createOffer"
        >
          <IconPlus />
          <span class="filter-top__element__text office__text-create">
            {{ $t('offerList.header.button.create_offer') }}
          </span>
        </div>
      </div>

      <div class="filter-top__right-group">
        <div
          v-if="isActiveTab && !isEdit"
          class="filter-top__element filter-button"
          @click="$emit('edit')"
        >
          <IconPanEdit />
          <span class="filter-top__element__element-text office__text-management">
            {{ $t('offerList.header.button.control') }}
          </span>
        </div>

        <div
          v-else-if="isActiveTab && isEdit"
          class="filter-top__element filter-button"
        >
          <span
            class="filter-top__element__element-text"
            @click="$emit('edit')"
          >
            {{ $t('offerList.header.button.accept') }}
          </span>
          <span
            class="filter-top__element__element-text filter-top__element__element-text_warm"
            :class="{'filter-top__element__element-text_disabled': selectedOffer.length === 0}"
            @click="cancelOffer"
          >
            {{ $t('offerList.header.button.cancel_offer') }}
          </span>
        </div>

        <!-- Скрыто на время -->
        <component
          :is="layout.label"
          v-for="layout in layouts"
          :key="layout.value"
          style="display: none"
          class="filter-top__element__icon"
          :class="layout.class"
          :isActive="currentLayout === layout.value"
          @click="$emit('changeLayout', layout.value)"
        />
      </div>
    </div>

    <div class="filter-bottom">
      <div class="filter-top__left-group">
        <span
          v-for="item in statusGroups"
          :key="item.value"
          class="filter-top__element__text"
          :class="{'filter-top__element__text_active': filters.statusGroup === item.value}"
          @click="changeStatus(item.value)"
        >{{ item.label }} {{ office && office[item.count] >= 0 ? `(${office[item.count]})` : '' }}</span>
      </div>

      <!-- Скрыто на время -->
      <div
        style="display: none"
        class="filter-top__right-group"
      >
        <span
          v-for="item in statusOwners"
          :key="item.value"
          class="filter-top__element__text"
          :class="{'filter-top__element__text_active': filters.statusOwners === item.value}"
          @click="changeOffer(item.value)"
        >{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import IconPlus from '@/components/common/icons/IconPlus'
import IconPanEdit from '@/components/common/icons/IconPanEdit'
import IconTable from '@/components/common/icons/IconTable'
import IconGrid from '@/components/common/icons/IconGrid'
import OfferFilterBar from '@/components/offer/OfferFilterBar'

export default {
  name: 'OfferFilterBarCenter',
  components: { IconGrid, IconTable, IconPanEdit, IconPlus },
  mixins: [OfferFilterBar],
  computed: {
    office () {
      return this.$store.state.office.userOffice
    },
    isActiveTab () {
      return this.filters.statusGroup === 'active'
    }
  },
}
</script>

<style lang="sass" scoped>
  .office
    &__filter
      display: flex
      flex-direction: column

    &__icon__grid
      margin-left: 50px
      margin-right: 20px
      cursor: pointer
    &__icon__table
      cursor: pointer

    &__text-management
      @extend %button-text
      color: rgba(0, 0, 0, 0.61)
      margin-left: 8px

  .filter-bottom
    margin-top: 10px

</style>
