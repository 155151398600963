<template>
  <AppLoader
    v-if="!!showLoad"
    class="full-height"
  />
  <div v-else-if="list.length">
    <transition-group
      ref="list"
      tag="div"
      class="grid-list"
      :class="{'start-alignment': alignStart}"
    >
      <slot />
    </transition-group>
    <AppLoadMore />
  </div>
  <div
    v-else
    class="empty-list mx-auto"
    style="margin-top: 150px"
  >
    <span>
      <slot name="empty">
        {{ $t('empty_list') }}
      </slot>
    </span>
  </div>
</template>


<script>
import AppLoader from '@/components/AppLoader'
import userUtil from '@/utils/user-util'
import { scrollIsBottom } from '@/utils/scroll_utils'
import AppLoadMore from '@/components/common/simple/AppLoadMore'

export default {
  name: 'AppGridList',
  components: { AppLoader, AppLoadMore },
  props: {
    list: {
      type: Array,
      required: true
    },
    event: {
      type: Function
    },
    isShowLoad: {
      type: Boolean,
      default: false
    },
    alignStart: {
      type: Boolean,
      default: false
    },
    defaultSize: {
      type: Number,
      default: 20
    },
  },
  data () {
    return {
      showLoad: this.isShowLoad,
      page: 1,
    }
  },
  computed: {
    pageHasOfficeSelect () {
      return this.$route.meta.hasOfficeSelect
    },
    officeSelected () {
      return this.$store.state.office.userOffice || null
    },
    user () {
      return this.$store.state.users.info
    }
  },
  watch: {
    '$route.params.id': function (officeId) {
      if (!officeId) {
        return
      }
      if (this.event && this.pageHasOfficeSelect && userUtil.isRoot(this.user)) {
        this.showLoad = true
        this.page = 1
        this.$store.dispatch('clearTableItems')
        this.event().finally(() => {
          this.page++
          this.showLoad = false
        })
      }
    }
  },
  created () {
    if (this.officeSelected || !this.pageHasOfficeSelect || !userUtil.isRoot(this.user)) {
      this.showLoad = this.isShowLoad

      if (this.event) {
        this.event().finally(() => {
          this.showLoad = false
        })
      } else {
        this.showLoad = this.isShowLoad
      }
    }
  },
  beforeUpdate () {
    this.showLoad = this.isShowLoad
  },
  mounted () {
    // document.querySelector('.wb-main-wrapper').addEventListener('scroll', this.loadPagination)
    // this.$once('hook:beforeDestroy', () => {
    //   document.querySelector('.wb-main-wrapper').removeEventListener('scroll', this.loadPagination)
    // })
  },
  methods: {
    loadPagination (event) {
      const { list } = this.$refs
      if (!list) return
      if (this.event && scrollIsBottom(event.target)) {
        this.event(this.page, this.defaultSize)
          .then(() => {
            this.page++
          })
      }
    }
  },
}
</script>

<style type="text/scss" scoped>
  .grid-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .start-alignment {
    justify-content: flex-start;
  }

  .empty-list{
    @include fontStyle(800, 18px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin-top: 30px;
  }
</style>
