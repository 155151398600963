
<script>
import { modalName } from '@/views/PageOfferList'
import IconGrid from '@/components/common/icons/IconGrid'
import IconTable from '@/components/common/icons/IconTable'

export default {
  name: 'OfferFilterBar',
  component: { IconGrid, IconTable },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    selectedOffer: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    isScroll: {
      type: Boolean,
      required: false,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    currentLayout: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      modalName,
      statusGroups: [
        { label: this.$t('offerList.header.filter.statusGroups.active'), value: 'active', count: 'activeOffers' },
        { label: this.$t('offerList.header.filter.statusGroups.created'), value: 'created', count: 'scheduledOffers' },
        { label: this.$t('offerList.header.filter.statusGroups.finish'), value: 'inactive', count: '0' },
      ],
      statusOwners: [
        { label: this.$t('offerList.header.filter.statusOwners.me'), value: 'me' },
        { label: this.$t('offerList.header.filter.statusOwners.other'), value: 'owner' },
      ],
      layouts: [
        { label: IconGrid, value: 'grid', class: 'office__icon__grid' },
        { label: IconTable, value: 'table', class: 'office__icon__table' }
      ]
    }
  },
  methods: {
    changeStatus (type) {
      this.$emit('changeStatus', type)
    },
    changeOffer (type) {
      this.$emit('changeOffer', type)
    },
    createOffer () {
      this.$emit('createOffer')
    },
    cancelOffer () {
      if (this.selectedOffer.length > 0) {
        this.$emit('openModal', modalName.removeOffer)
      }
    },
  }
}
</script>
