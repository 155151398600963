<template>
  <AppMainContainer
    center-class="offer"
    :show-modal="!!currentModal || showBuyModal"
    @isScroll="(val) => {isScroll = val}"
    @closeModal="closeModal"
  >
    <template #subheader>
      <PageHeader :isAdmin="isOrganizationRoot && !officeExists" />
      <OfficeSelect />
      <offer-filter-bar-header
        v-if="officeExists && currentUser && isScroll"
        :filters="filters"
        :selected-offer="selected"
        :manageable="offersOffice && offersOffice.length > 0"
        :is-edit="isEdit"
        :user="currentUser"
        :currentLayout="currentLayout"
        @changeStatus="filters.statusGroup = $event"
        @changeOffer="filters.statusOwners = $event"
        @edit="isEdit = !isEdit"
        @changeLayout="currentLayout = $event"
        @openModal="currentModal = $event"
        @createOffer="onCreateOffer()"
      />
    </template>

    <template
      v-if="officeExists"
      #center
    >
      <offer-filter-bar-center
        v-if="currentUser && !isScroll"
        :filters="filters"
        :selected-offer="selected"
        :is-edit="isEdit"
        :user="currentUser"
        :currentLayout="currentLayout"
        @changeStatus="onFilterChange"
        @changeOffer=" filters.statusOwners = $event"
        @edit="isEdit = !isEdit"
        @changeLayout="currentLayout = $event"
        @openModal="currentModal = $event"
        @createOffer="onCreateOffer"
      />

      <app-grid-list
        v-if="officeExists && currentLayout === 'grid'"
        class="test"
        alignStart
        :list="offersOffice"
        item-class="offers__grid__item offer-grid-item"
        :isShowLoad="loadList"
        :event="fetchOfferByOffice"
      >
        <OfferGridItem
          v-for="(offer, index) in offersOffice"
          :key="`${index}-${offer.eventTimestamp}`"
          :offer="offer"
          :selectedOffer="selected"
          :is-edit-mode="isEdit"
          @click="clickOffer"
        />
      </app-grid-list>

      <OfferTable
        v-else
        :offersOffice="offersOffice"
        :is-edit="isEdit"
        :offer-checked="selected"
        :event="fetchOfferByOffice"
        @selectCombobox="clickOffer"
        @click="clickOffer"
        @selectAll="selectAll"
      />
      <p
        v-if="!hasRegion"
        style="color: red; text-align: center; padding: 8px"
      >
        У вашего объекта не назначен регион. <br>
        Для отображения вакансии при создании офферов, необходимо чтобы объект был прикреплен к региону
      </p>
    </template>

    <template #modal>
      <AppModalSmall
        v-if="currentModal === modalName.removeOffer"
        style="padding: 70px 106px"
      >
        <h1 class="modal__header">
          {{ `Вы уверены, что хотите отменить ${getSelectedListChildrenNumber}
${_pluriaizeOffers(getSelectedListChildrenNumber)}?` }}
        </h1>

        <div
          class="modal__button"
        >
          <AppLoader v-if="isLoading" />
          <AppButton
            v-else
            class="button__warm-action"
            @click.native="cancelOffer"
          >
            Отменить офферы
          </AppButton>
        </div>

        <a
          v-if="!isLoading"
          class="modal__button-cancel"
          @click.prevent="currentModal = ''"
        >Отмена</a>
      </AppModalSmall>

      <OfferModalForm
        v-if="currentModal === modalName.createOffer"
        :office="officeApi_officeUser"
        :offers="offersOffice"
        style="padding: 30px"
        @close="currentModal = ''"
        @addBalance="onShowBuyModal"
        @buyOffer="updateOfficeAndOffer"
      />
    </template>
  </AppMainContainer>
</template>


<script>
import AppConfig from '@/utils/settings'
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import OfferFilterBarCenter from '@/components/offer/OfferFilterBarCenter'
import AppGridList from '@/components/common/AppGridList'
import OfficeSelect from '@/components/common/OfficeSelect'
import OfferGridItem from '@/components/offer/OfferGridItem'
import OfferFilterBarHeader from '@/components/offer/OfferFilterBarHeader'
import OfferTable from '@/components/offer/OfferTable'
import AppModalSmall from '@/components/common/modal/AppModalSmall'
import AppButton from '@/components/common/simple/AppButton'
import OfferModalForm from '@/components/offer/OfferModalForm'
import { routeList } from '@/router/office'
import officeApi from '@/mixins/office/officeApi'
import AppLoader from '@/components/AppLoader'
import userUtil from '@/utils/user-util'
import PageHeader from '@/components/subheaders/PageHeader.vue'
import { pluriaizeOffers } from '@/utils/plural-util'

const modalName = Object.freeze({
  removeOffer: 'removeOffer',
  createOffer: 'createOffer',
})

export { modalName }

export default {
  name: 'PageOfferList',
  components: {
    PageHeader,
    OfficeSelect,
    AppLoader,
    OfferModalForm,
    AppButton,
    AppModalSmall,
    OfferTable,
    OfferFilterBarHeader, OfferGridItem,
    AppGridList,
    OfferFilterBarCenter, AppMainContainer,
  },
  mixins: [officeApi],
  data () {
    return {
      isLoading: false,
      loaderData: {
        OFFER_FORM: 'OFFER_FORM',
        OFFER_LIST: 'OFFER_LIST',
        PAGE: 'PAGE',
      },
      currentLoader: null,
      filters: {
        statusGroup: this.$route.query.statusGroup || 'active',
        statusOwners: 'me'
      },
      isEdit: false,
      isScroll: false,
      currentLayout: 'grid',
      selected: [],
      currentModal: '',
      showBuyModal: false,
      modalName
    }
  },
  computed: {
    getSelectedListChildrenNumber () {
      let canceledNumber = 0
      for (const id of this.selected) {
        canceledNumber += this.getOfferChildrenNumber(id)
      }

      return canceledNumber
    },
    hasRegion () {
      if (AppConfig.getVacancyMultiRegion() === '1') {
        return (!!this.currentOffice && this.currentOffice.region && this.currentOffice.region.id)
      }
      return true
    },
    loadList () {
      return this.currentLoader === this.loaderData.OFFER_LIST
    },
    offersOffice () {
      let currentOfferList = []
      if (this.isEdit) {
        currentOfferList = this.$store.state.offer.items.filter((item) => !item.isScheduler)
      }
      currentOfferList = this.$store.state.offer.items.map((item) => {
        delete item.eventTimestamp
        const ts = new Date().getMilliseconds() * Math.random()
        return {
          ...item,
          eventTimestamp: ts
        }
      })

      return currentOfferList.sort((a, b) => {
        if (a.startAt > b.startAt) {
          return 1
        }
        if (a.startAt < b.startAt) {
          return -1
        }
        return 0
      })
    },
    currentUser () {
      return this.$store.state.users.info
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.currentUser) || userUtil.isAdmin(this.currentUser)
    },
    isOrganizationAdmin () {
      return userUtil.isAdmin(this.currentUser)
    },
    currentOffice () {
      return this.$store.state.office.userOffice
    },
    officeExists () {
      return !!this.$store.state.office.userOffice && !!this.$store.state.office.userOffice.id
    }
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        this.currentLoader = this.loaderData.PAGE
        this.fetchOfferByOffice()
      }
    },
    '$route.query' (val) {
      if (val.statusGroup) {
        this.filters.statusGroup = val.statusGroup
      }
    },
    '$route.params' (val) {
      if (val && this.isOrganizationAdmin) {
        this.fetchOfferByOffice()
      }
    },
    isEdit: {
      handler (val) {
        if (!val) this.selected = []
      }
    },
  },
  beforeDestroy () {
    this.$store.commit('offer/clearOfficeItems')
  },
  created () {
    this.currentLoader = this.loaderData.PAGE
    this.$store.dispatch('temperature/fetchTemperatureList')
    this.eventsBus.$on(
      'closeOfferModal', (data) => {this.currentModal = ''}
    )
    this.eventsBus.$on(
      this.eventNames.ON_OFFER_INVITATION_REJECTED_NOTIFICATION, (data) => {
        const { extra: { resource: { status } } } = data
        if (status && status === 'accepted') {
          this.silentUpdate()
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_OFFER_RESPOND_LIST_INCREASED_NOTIFICATION, (data) => {
        this.silentUpdate()
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_OFFER_RESPOND_CANCELLED_NOTIFICATION, (data) => {
        this.silentUpdate()
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_OFFER_WORKFLOW_SYSTEM_CANCEL_NOTIFICATION, () => {
        this.$store.commit('offer/clearOfficeItems')
        this.silentUpdate()
      }
    )
  },
  methods: {
    onShowBuyModal () {
      this.$store.dispatch('popup/showModal', { key: 'showOfferBuyForm' })
    },
    fetchOffice () {
      this.$store.dispatch('office/fetchOfficeById', { officeId: this.currentOffice.id })
    },
    fetchOfferByOffice (page, size) {
      this.currentLoader = this.loaderData.OFFER_LIST

      return this.$store.dispatch('offer/fetchOffersListByOffice',
        { ...this.filters, page, size }).then((response) => {
        this.currentLoader = null

        return response
      })
    },
    updateOfficeAndOffer (showLoader = true) {
      this.fetchOffice()
      this.fetchOfferByOffice(showLoader)
    },
    silentUpdate () {
      this.fetchOffice()
      this.$store.dispatch('offer/fetchOffersListByOffice',
        { ...this.filters, page: 1, size: 50 })
    },
    selectAll (isSelected) {
      if (this.isEdit) {
        if (isSelected) {
          this.selected = []
          this.selected = this.offersOffice.map((offer) => offer.id)
        } else {
          this.selected = []
        }
      }
    },
    onFilterChange ($event) {
      this.isEdit = false
      this.$store.commit('offer/clearOfficeItems')
      this.filters.statusGroup = $event
    },
    clickOffer (offer) {
      const { offerId, id } = offer
      if (offerId) {
        if (this.isEdit) {
          if (this.selected.includes(id)) {
            this.selected = this.selected.filter((item) => item !== id)
          } else {
            const findOffer = this.selected.findIndex((item) => item.id === id)
            if (findOffer === -1) {
              this.selected.push(id)
            }
          }
        } else if (!this.isEdit) {
          this.$router.push({
            name: routeList.OFFICE_OFFER_ID_NAME,
            params: { id: this.officeApi_officeUser.id, offerId: offerId }
          })
        }
      } else {
        this.$router.push({
          name: routeList.OFFICE_OFFER_WORKDAY_LIST_ROUTE_NAME,
          params: { id: this.officeApi_officeUser.id, offerId: id }
        })
      }
    },
    onCreateOffer () {
      this.$router.push({ name: routeList.OFFER_CREATE_PAGE_NAME })

      // this.filters.statusGroup = 'active'
      // this.currentModal = this.modalName.createOffer
    },
    cancelOffer () {
      // const idList = []
      // for (const id of this.selected) {
      //   // idList.push({ id, canceledNumber: -1 })
      //   const offerList = this.offersOffice.filter((scheduler) => scheduler.id === id)
      //     .map((offer) => ({ id: offer.offerId, canceledNumber: -1 }))
      // }

      const idList = this.offersOffice.filter((scheduler) => this.selected.includes(scheduler.id))
        .map((offer) => ({ id: offer.offerId, canceledNumber: -1 }))
      try {
        this.isLoading = true
        this.$store.dispatch('offer/fetchOffersCancelById', { idList })
          .then(() => {
            this.$store.commit('offer/clearOfficeItems')
            this.$store.dispatch('offer/fetchOffersListByOffice', { ...this.filters })
            this.$store.dispatch('office/fetchOfficeById', { officeId: this.currentOffice.id })
          }).then(() => {
            this.closeModal()
            this.isEdit = false
            this.isLoading = false
          })
      } catch (e) {
        this.isLoading = false
      }
    },
    getOfferChildrenNumber (id) {
      const offerList = this.offersOffice.filter((item) => item.id === id)
      if (offerList.length) {
        return offerList[0].childrenNumber
      }

      return null
    },
    _pluriaizeOffers (offerNumber) {
      return pluriaizeOffers(offerNumber)
    },
    closeModal () {
      this.currentModal = ''
      this.showBuyModal = false
    }
  }
}
</script>

<style type="text/scss" scoped>

.offer-grid-item{
  border: 1px solid red;
}
</style>
