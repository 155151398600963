<template>
  <div class="load-more">
    <!--    <AppButton-->
    <!--      style="display: none"-->
    <!--      class=""-->
    <!--    >-->
    <!--      Загузить еще ...-->
    <!--    </AppButton>-->
  </div>
</template>

<script>
// import AppButton from '@/components/common/simple/AppButton'

export default {
  name: 'AppLoadMore',
  // components: { AppButton },
  data () {
    return {
    }
  },
  computed: {

  },
}
</script>

<style type="text/scss" scoped>
.load-more{
  margin: 16px auto 0;
  text-align: center
}
</style>
