<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('click')">
    <g :opacity="isActive ? 0.5 : 0.2">
      <path d="M1 2C1 1.44772 1.44772 1 2 1H7C7.55228 1 8 1.44772 8 2V7C8 7.55228 7.55228 8 7 8H2C1.44772 8 1 7.55228 1 7V2Z" fill="black"/>
      <path d="M11.5 2C11.5 1.44772 11.9477 1 12.5 1H17.5C18.0523 1 18.5 1.44772 18.5 2V7C18.5 7.55228 18.0523 8 17.5 8H12.5C11.9477 8 11.5 7.55228 11.5 7V2Z" fill="black"/>
      <path d="M22 2C22 1.44772 22.4477 1 23 1H28C28.5523 1 29 1.44772 29 2V7C29 7.55228 28.5523 8 28 8H23C22.4477 8 22 7.55228 22 7V2Z" fill="black"/>
      <path d="M1 12.5C1 11.9477 1.44772 11.5 2 11.5H7C7.55228 11.5 8 11.9477 8 12.5V17.5C8 18.0523 7.55228 18.5 7 18.5H2C1.44772 18.5 1 18.0523 1 17.5V12.5Z" fill="black"/>
      <path d="M11.5 12.5C11.5 11.9477 11.9477 11.5 12.5 11.5H17.5C18.0523 11.5 18.5 11.9477 18.5 12.5V17.5C18.5 18.0523 18.0523 18.5 17.5 18.5H12.5C11.9477 18.5 11.5 18.0523 11.5 17.5V12.5Z" fill="black"/>
      <path d="M22 12.5C22 11.9477 22.4477 11.5 23 11.5H28C28.5523 11.5 29 11.9477 29 12.5V17.5C29 18.0523 28.5523 18.5 28 18.5H23C22.4477 18.5 22 18.0523 22 17.5V12.5Z" fill="black"/>
      <path d="M1 23C1 22.4477 1.44772 22 2 22H7C7.55228 22 8 22.4477 8 23V28C8 28.5523 7.55228 29 7 29H2C1.44772 29 1 28.5523 1 28V23Z" fill="black"/>
      <path d="M11.5 23C11.5 22.4477 11.9477 22 12.5 22H17.5C18.0523 22 18.5 22.4477 18.5 23V28C18.5 28.5523 18.0523 29 17.5 29H12.5C11.9477 29 11.5 28.5523 11.5 28V23Z" fill="black"/>
      <path d="M22 23C22 22.4477 22.4477 22 23 22H28C28.5523 22 29 22.4477 29 23V28C29 28.5523 28.5523 29 28 29H23C22.4477 29 22 28.5523 22 28V23Z" fill="black"/>
    </g>
  </svg>


</template>

<script>
    export default {
        name: "IconGrid",
        props: {
            isActive: {
                type: Boolean
            }
        }
    }
</script>

<style scoped>

</style>
