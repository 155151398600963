<template>
  <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M4.5 5L4.37114e-07 1.66869e-07L9 9.53674e-07L4.5 5Z" fill="black"/>
  </svg>
</template>

<script>
    export default {
        name: "IconArrowDown"
    }
</script>

<style scoped>

</style>
