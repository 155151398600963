<template>
  <svg width="22" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('click')">
    <path d="M11.1939 11.7384L2.83365 4H19.5541L11.1939 11.7384Z" fill="#8BD118"/>
    <path d="M0 6.15385C0 5.57021 0.237116 5.04078 0.62218 4.65288L7.59441 11.1065L0.736442 17.4543C0.284555 17.0598 0 16.4855 0 15.8462V6.15385Z" fill="#8BD118"/>
    <path d="M3.06365 18H19.2302L13.288 12.4997L11.1938 14.4381L9.05274 12.4564L3.06365 18Z" fill="#8BD118"/>
    <path d="M14.7464 11.1498L21.4081 17.316C21.7753 16.9312 22 16.4143 22 15.8462V6.15385C22 5.66409 21.833 5.21252 21.5518 4.8507L14.7464 11.1498Z" fill="#8BD118"/>
    <circle cx="21.5" cy="5.5" r="4.5" fill="#8BD118" stroke="white" stroke-width="2"/>
  </svg>


</template>

<script>
    export default {
        name: "IconEmailActive"
    }
</script>

<style scoped>

</style>
