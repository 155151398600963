<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('click')">
    <g :opacity="isActive ? 0.5 : 0.2">
      <rect x="1" y="2" width="4" height="4" rx="1" fill="black"/>
      <rect x="8" y="3" width="21" height="2" rx="1" fill="black"/>
      <rect x="1" y="24" width="4" height="4" rx="1" fill="black"/>
      <rect x="8" y="25" width="21" height="2" rx="1" fill="black"/>
      <rect x="1" y="13" width="4" height="4" rx="1" fill="black"/>
      <rect x="8" y="14" width="21" height="2" rx="1" fill="black"/>
    </g>
  </svg>

</template>

<script>
    export default {
        name: "IconTable",
        props: {
            isActive: {
                type: Boolean
            }
        }
    }
</script>

<style scoped>

</style>
