<template>
  <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('click')">
    <g opacity="0.2">
      <path d="M8.5863 3.80455C8.9034 3.49091 9.43192 3.38637 9.96043 3.38637C11.4403 3.38637 12.603 4.53637 12.603 6C12.603 7.46364 11.3346 8.61364 9.96043 8.61364C8.4806 8.61364 7.31787 7.35909 7.31787 6H9.96043L8.5863 3.80455Z" fill="black"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.96036 0.25C13.8713 0.25 17.2538 2.34091 19.2622 5.37273C19.5793 5.79091 19.5793 6.20909 19.2622 6.62727C17.2538 9.65909 13.8713 11.75 9.96036 11.75C6.04938 11.75 2.6669 9.65909 0.658553 6.62727C0.447149 6.31364 0.447149 5.79091 0.658553 5.37273C2.6669 2.34091 6.04938 0.25 9.96036 0.25ZM5.94367 5.89545C5.94367 8.09091 7.74061 9.86818 9.96036 9.86818C12.1801 9.86818 13.9771 8.19545 13.9771 5.89545C13.9771 3.7 12.1801 1.92273 9.96036 1.92273C7.74061 1.92273 5.94367 3.7 5.94367 5.89545Z" fill="black"/>
    </g>
  </svg>
</template>

<script>
    export default {
        name: "IconEye"
    }
</script>

<style scoped>

</style>
