<template>
  <app-table
    :keys="keys"
    :array="offersOffice"
    :isEdit="isEdit"
    :checked-item="offerChecked"
    class="offer__table"
    :event="event"
    is-show-load
    @selectCombobox="(item) => { this.$emit('selectCombobox', item) }"
    @click="(item) => { this.$emit('click', item) }"
    @selectAll="(val) => {this.$emit('selectAll', val)}"
  >
    <template #employers="{item: offer}">
      <span
        v-show="!isEdit"
        class="offer__table__employee"
      >
        <IconEmployee style="vertical-align: middle;" />
      </span>
    </template>

    <template #vacancy.title="{item: offer}">
      <span class="offer__table__vacancy">
        {{ offer.vacancy.title }}
      </span>
    </template>

    <template #startDate="{item: offer}">
      <span class="offer__table__date">
        {{ offer.startDate }}
        <span class="offer__table__date__time">{{ offer.startTime }}</span>
      </span>
    </template>

    <template #endDate="{item: offer}">
      <span class="offer__table__date">
        {{ offer.endDate }}
        <span class="offer__table__date__time">{{ offer.endTime }}</span>
      </span>
    </template>

    <template #duration="{item: offer}">
      <div class="offer__table__duration">
        {{ offer.duration }}
      </div>
    </template>

    <template #expense="{item: offer}">
      <AppTextMoney
        :money="offer.expense"
        class="price__text_bold"
      />
    </template>

    <template #respondNumber="{item: offer}">
      <span class="offer__table__feedback">
        Отклики
        <span class="offer__table__feedback__count">{{ offer.respondNumber }}</span>
        <span class="offer__table__feedback__new"> + {{ 0 }}</span>
      </span>
    </template>

    <template #invitationNumber="{item: offer}">
      <span class="offer__table__invitationNumber">
        Приглашения
        <span class="offer__table__invitationNumber__count">{{ offer.invitationNumber }}</span>
      </span>
    </template>

    <template #messageNumber="{item: offer}">
      <span class="offer__table__email">
        <IconEmail
          v-if="!offer.unreadMessageNumber"
          style="vertical-align: middle;text-align: center"
        />
        <IconEmailActive
          v-else
          style="vertical-align: middle;"
        />
      </span>
    </template>
  </app-table>
</template>

<script>
import AppTable from '@/components/common/AppTable'
import IconEmployee from '@/components/common/icons/IconEmployee'
import IconEmail from '@/components/common/icons/IconEmail'
import IconEmailActive from '@/components/common/icons/IconEmailActive'
import AppTextMoney from '@/components/common/AppTextMoney'

export default {
  name: 'OfferTable',
  components: { AppTextMoney, IconEmailActive, IconEmail, IconEmployee, AppTable },
  props: {
    offersOffice: {
      type: Array,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    },
    offerChecked: {
      type: Array,
      required: true
    },
    event: {
      type: Function
    }
  },
  data () {
    return {
      keys: [
        { label: '', value: 'employers', config: { canSort: false } },
        { label: 'Вакансия', value: 'vacancy.title', config: { canSort: true } },
        { label: 'Начало смены', value: 'startDate', config: { canSort: true } },
        { label: 'Конец смены', value: 'endDate', config: { canSort: true } },
        { label: 'Смена', value: 'duration', config: { canSort: false } },
        { label: 'Резерв', value: 'expense', config: { canSort: false } },
        { label: 'Отклики', value: 'respondNumber', config: { canSort: false } },
        { label: 'Приглашения', value: 'invitationNumber', config: { canSort: false } },
        { label: '', value: 'messageNumber', config: { canSort: false } },
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
  $countColor: #A7A7A7
  $fontWeightBold: 800

  .offer__table
    font-family: $mainFont
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 15px
    &__employee
      padding-right: 10px
    &__vacancy
      font-weight: 600

    &__invitationNumber
      font-weight: $fontWeightBold
      &__count
        font-weight: 500
        color: $countColor
    &__date
      color: rgba(0, 0, 0, 0.60)
      &__time
        font-weight: $fontWeightBold

    &__duration
      font-weight: $fontWeightBold

    &__sum
      font-weight: $fontWeightBold

    &__feedback
      font-weight: 600
      &__count
        color: $countColor
      &__new
        color: #8BD118
    &__count
      color: $countColor
    &__email
      padding-right: 20px
</style>
