<template>
  <AppModalMiddle>
    <ModalHeader @close="$emit('close')">
      {{ $t('offer.creation.offer_creation') }}
    </ModalHeader>

    <PageHeader
      :isAdmin="false"
      is-modal-header
    />

    <p
      v-if="!hasRegion"
      style="color: red; text-align: center; padding: 8px"
    >
      У вашего объекта не назначен регион. Для отображения вакансии необходимо чтобы объект был прикреплен к региону
    </p>

    <OfferVacancySelect
      :form.sync="form"
      :validationFields="validationFields"
    />

    <OfferDatesSelect
      :startDate.sync="startDate"
      :endDate.sync="endDate"
      :dayDiff.sync="dayDiff"
      :isPeriod.sync="isPeriod"
      :startPeriod.sync="startPeriod"
      :endPeriod.sync="endPeriod"
      :shiftHours.sync="shiftHours"
      :shiftHoursFloat="shiftHoursFloat"
      :lunchBreak.sync="lunchBreak"
      :vaccine-requirement.sync="vaccineRequirement"
      :min-start-date="minStartDate"
      :min-relay-duration="minRelayDuration"
      :max-relay-duration="maxRelayDuration"
      :min-break-duration="minBreakDuration"
      :max-break-duration="maxBreakDuration"
      :form.sync="form"
      :shift-full-duration="getShiftFullDuration"
      :shift-duration-full-filled="shiftDurationFullFilled"
      @onDateChange="onDateChange"
    />

    <OfferAdditionalSettings
      :form.sync="form"
    />

    <!-- <OfferResult
      :form="form"
      :shift-hours="shiftHours"
      :office="office"
    /> -->

    <div
      v-if="hasRegion"
      class="modal__input-group d-flex flex-row align-items-baseline"
    >
      <div>
        <div class="d-flex flex-row">
          <div class="modal__result-group info-form">
            <span class="modal-text modal-text--text-gray">
              {{ $t('offer.creation.hours_payed') }}
            </span>
            <h2
              class="text-result reserv-value"
            >
              {{ shiftHours }} мин
            </h2>
          </div>
          <div class="modal__result-group info-form">
            <span class="modal-text modal-text--text-gray">
              Физлица <br>(с налогами)
            </span>
            <h2
              class="text-result reserv-value"
              :class="{'text-result_not-have': calcDifferencePrice > 0}"
            >
              {{ getExpense }} &#8381;
            </h2>
          </div>
          <div class="modal__result-group info-form">
            <span class="modal-text modal-text--text-gray">
              <!--              {{ $t('offer.creation.tax') }}-->
              Самозанятые
            </span>
            <h2
              class="text-result nalog-value"
              :class="{'text-result_not-have': calcDifferencePrice > 0}"
            >
              {{ getSelfEmployedPayment }} &#8381;
            </h2>
          </div>
        </div>
        <div
          v-if="calcDifferencePrice > 0"
          class="modal-text modal__offer__text-error text-center mt-3"
        >
          {{ $t('offer.creation.not_enough_funds') }}:
          <span class="modal__offer__text-error__count">
            {{ calcDifferencePrice }} &#8381;
          </span>
        </div>
      </div>
      <div
        class="modal__result-group ml-auto pb-3"
      >
        <div
          style="position: relative"
          class="modal-text offer__form__employee"
        >
          <span class="mr-2">
            {{ $t('offer.creation.staff_number_required') }}
          </span>
          <div
            class="employee-number-btns"
          >
            <IconMinus
              class="mr-2"
              @click="changeCountEmpLoyee(-1)"
            />
            <div class="count">
              {{ form.laborerNumber }}
            </div>
            <div style="position: absolute; bottom: 0; left: 0;top: 20px;right: 0">
              <div
                v-if="isPeriod"
                style="width: 100%; margin: auto; text-align: left; color: orange"
              >
                {{ `Офферов требуется:` }}
                <span style="display: inline-block; margin-left: 10px">
                  {{ `${requiredLaborer}` }}
                </span>
              </div>
            </div>
            <IconPlusSmall
              class="ml-2"
              @click="changeCountEmpLoyee(1)"
            />
          </div>

          <div
            v-if="office.availableOffersNumber <= requiredLaborer"
            class="notification"
          >
            {{ $t('offer.creation.offers_required') }}
            <AppButton
              class="button__notification"
              @click.native="onRefillOffer"
            >
              {{ $t('offer.creation.top_up') }}
            </AppButton>
          </div>
        </div>

        <AppLoader v-if="isLoading" />
        <AppButton
          v-if="!isLoading"
          class="mt-3"
          :disabled="!isFormValid"
          @click.native="submitForm"
        >
          {{ $t('offer.creation.publish_offer') }}
        </AppButton>
        <p
          v-if="errorDisplayed"
          style="color: red"
        >
          {{ $t('offer.creation.error.server_error') }}
        </p>
        <p
          v-if="showLocalError"
          style="color: red"
        >
          {{ $t('offer.creation.error.start_timeout_error') }}
        </p>
      </div>
    </div>
  </AppModalMiddle>
</template>

<script>
import AppModalMiddle from '@/components/common/modal/AppModalMiddle'
import OfferVacancySelect from '@/components/offer/OfferVacancySelect'
import OfferAdditionalSettings from '@/components/offer/OfferAdditionalSettings'
import OfferDatesSelect from '@/components/offer/OfferDatesSelect'
import AppConfig from '@/utils/settings'
// import OfferResult from '@/components/offer/OfferResult'
import AppButton from '@/components/common/simple/AppButton'
import IconMinus from '@/components/common/icons/IconMinus'
import IconPlusSmall from '@/components/common/icons/IconPlusSmall'
import { isNonNull } from '@/utils/validation'
import ModalHeader from '@/components/common/modal/ModalHeader'
import taxMixin from '@/mixins/taxMixin.vue'
import AppLoader from '@/components/AppLoader'
import AppWorkerTypeUtil from '@/utils/worker-type-util'
import PageHeader from '@/components/subheaders/PageHeader.vue'
// import moment from 'moment-timezone'

const REQUIRED_QR_CODE_ID = 'qr-code'

export default {
  name: 'OfferModalForm',
  components: {
    PageHeader,
    OfferVacancySelect,
    OfferDatesSelect,
    OfferAdditionalSettings,
    // OfferResult,
    ModalHeader,
    IconPlusSmall,
    IconMinus,
    AppButton,
    AppModalMiddle,
    AppLoader,
  },
  mixins: [taxMixin],
  props: {
    office: {
      type: Object
    },
    offers: {
      type: Array,
    },
  },
  data (vm) {
    return {
      minStartDateInterval: null,
      minRelayDuration: null,
      maxRelayDuration: null,
      minBreakDuration: null,
      maxBreakDuration: null,
      minStartDate: undefined,
      vaccineRequirement: {
        value: 'not',
        optionList: [
          { id: 'not', title: 'Не нужна', value: 'not' },
          { id: REQUIRED_QR_CODE_ID, title: 'Нужна', value: REQUIRED_QR_CODE_ID },
        ]
      },
      isPeriod: false,
      startPeriod: null,
      dayDiff: 1,
      endPeriod: null,
      isLoading: false,
      errorDisplayed: false,
      showLocalError: false,
      localFormat: 'YYYY-MM-DD[T]HH:mm:ss',
      currentLoader: null,
      shiftHours: null,
      lunchBreak: '',
      startDate: null,
      endDate: null,
      form: {
        executorAssignmentType: null,
        vacancySelected: null,
        costPerHour: '',
        selfEmployerCostPerHour: '',
        temperature: 'normal',
        gender: '-1',
        hasTransportation: false,
        freeFood: false,
        laborerNumber: 1,
        minPay: 0,
        minSelfEmployerPay: 0,
        maxSelfEmployerPay: null,
        maxPay: null,
        isPrivate: null,
      },
      validationFields: {
        vacancySelected: [
          { text: '', valid: false, validator: isNonNull }
        ],
        costPerHour: [
          {
            text: '', valid: false,
            validator: (value) => {
              return (
                parseFloat(value) >= parseFloat(vm.form.minPay) &&
                parseFloat(value) <= parseFloat(vm.form.maxPay)
              )
            }
          },
          { text: '', valid: false, validator: isNonNull },
        ],
        selfEmployerCostPerHour: [
          {
            text: '', valid: false,
            validator: (value) => {
              return (
                parseFloat(value) >= parseFloat(vm.form.minSelfEmployerPay) &&
                parseFloat(value) <= parseFloat(vm.form.maxSelfEmployerPay)
              )
            }
          },
          { text: '', valid: false, validator: isNonNull },
        ],
        temperature: [
          { text: '', valid: true, validator: isNonNull }
        ],
      },
    }
  },
  computed: {
    getShiftFullDuration () {
      const val = this.lunchBreak
      const lunchValue = parseInt(`${val}`, 10)
      let lunchValueHours = 0
      if (lunchValue) {
        lunchValueHours = lunchValue / 60
      }
      // console.log(this.shiftHoursFloat + lunchValueHours, 'TOTAL DURATION')
      return this.shiftHoursFloat + lunchValueHours
    },
    shiftDurationFullFilled () {
      return this.getRelayMaxDuration < this.getShiftFullDuration
    },
    currentUser () {
      return this.$store.state.users.info
    },
    hasWorkerType () {
      if (!this.currentUser) {
        return false
      }

      return !!this.currentUser.organization && !!this.currentUser.organization.workerType
    },
    getWorkerType () {
      if (!this.hasWorkerType) {
        return undefined
      }

      return this.currentUser.organization.workerType
    },
    isSelfEmployedWorkerType () {
      return this.getWorkerType === AppWorkerTypeUtil.ALIAS_WORKER_TYPE_RU_SELF_EMPLOYED
    },
    isSimpleWorkerType () {
      return this.getWorkerType === AppWorkerTypeUtil.ALIAS_WORKER_TYPE_LEGAL_PERSON
    },
    acceptAllWorkers () {
      return !this.getWorkerType || this.getWorkerType === AppWorkerTypeUtil.ALIAS_WORKER_TYPE_ALL
    },
    hasVaccineRequirement () {
      return this.vaccineRequirement.value
    },
    requiredLaborer () {
      return parseInt(this.form.laborerNumber) * this.getWorkDays
    },
    shiftHoursFloat () {
      if (!this.shiftHours) {
        return 0
      }
      const [hours, minutes] = this.shiftHours.split(':')
      return parseFloat((parseInt(minutes, 10) / 60).toFixed(2)) + parseInt(hours, 10)
    },
    computedResultPrice () {
      if (!this.form.costPerHour) {
        return 0
      }

      return (parseInt(this.form.laborerNumber) * parseFloat(this.form.costPerHour) * this.shiftHoursFloat)
    },
    currentOffice () {
      return this.$store.state.office.userOffice
    },
    hasRegion () {
      if (AppConfig.getVacancyMultiRegion() === '1') {
        return (!!this.currentOffice && this.currentOffice.region && this.currentOffice.region.id)
      }
      return true
    },
    getWorkDays () {
      const workDay = this.isPeriod ? this.dayDiff : 1
      return workDay
    },
    getGain () {
      if (!this.form.costPerHour) {
        return 0
      }

      return this.calculateGain(this.shiftHoursFloat)
    },

    calcDifferencePrice () {
      if (!this.form.costPerHour) {
        return 0
      }

      return (Math.max(this.getSelfEmployedPayment, this.getExpense) - this.office.amount).toFixed(2)
    },

    getSelfEmployedPayment () {
      if (!this.form.costPerHour) {
        return 0
      }
      const payment = parseInt(this.form.laborerNumber) * (
        parseInt(this.form.selfEmployerCostPerHour) * this.shiftHoursFloat + this.getGain
      )

      return (payment * this.getWorkDays).toFixed(2)
    },
    getExpense () {
      if (!this.form.costPerHour) {
        return 0
      }
      const costPerHour = parseFloat(this.form.costPerHour)
      const duration = parseFloat(this.shiftHoursFloat)
      const tax = (this.calculateTax(costPerHour))
      const unit = parseFloat((costPerHour + tax + this.calculateGain(1)).toFixed(2))
      const expense = (unit * duration) * parseInt(this.form.laborerNumber)
      return (expense * this.getWorkDays).toFixed(2)
    },

    isFormValid () {
      if (!this.hasVaccineRequirement) {
        return false
      }

      let allValidateFields
      let isValidFields
      if (this.form.offerAccessType) {
        const privateFields = {
          ...this.validationFields
        }

        delete privateFields.selfEmployerCostPerHour
        delete privateFields.costPerHour
        // costPerHour: selfEmployerCostPerHour
        const isRemoteWorker = (this.form.minPay || this.form.minSelfEmployerPay)
        allValidateFields = Object.values(privateFields)
        isValidFields = allValidateFields.filter((value) => value.every((el) => el.valid))

        if (this.isPeriod && !(this.startPeriod && this.endDate)) {
          return false
        }

        return isRemoteWorker && !!this.startPeriod &&
          allValidateFields.length === isValidFields.length &&
          this.lunchBreak !== '' &&
          this.office.availableOffersNumber >= this.requiredLaborer &&
          this.calcDifferencePrice <= 0 && this.form.executorAssignmentType
      }

      allValidateFields = Object.values(this.validationFields)
      isValidFields = allValidateFields.filter((value) => value.every((el) => el.valid))

      if (this.isPeriod && !(this.startPeriod && this.endDate)) {
        return false
      }

      return !!this.startPeriod &&
        allValidateFields.length === isValidFields.length &&
        this.lunchBreak !== '' &&
      this.office.availableOffersNumber >= this.requiredLaborer &&
      this.calcDifferencePrice <= 0 && this.form.executorAssignmentType
    }


  },
  watch: {
    shiftHours (val) {
      this.updateBreakValue(val)
    },
    isPeriod (val) {
      this.setDates(undefined, val)
    },
    'form.vacancySelected' (val) {
      if (val) {
        const { regionSetting } = val
        if (regionSetting.length) {
          const regionData = regionSetting[0]

          this.form.costPerHour = regionData.payPerHour
          this.form.selfEmployerCostPerHour = regionData.selfEmployerPay
          // if (regionSetting[0].minPay < regionSetting[0].maxPay) {
          //   this.form.maxPay = regionSetting[0].maxPay
          // }

          if (regionSetting[0].hasFixedPay) {
            this.form.minPay = regionData.payPerHour
            this.form.maxPay = regionData.payPerHour
            this.form.minSelfEmployerPay = regionData.selfEmployerPay
            this.form.maxSelfEmployerPay = regionData.selfEmployerPay
          } else {
            this.form.minPay = regionData.minPay
            this.form.maxPay = regionData.maxPay
            this.form.minSelfEmployerPay = regionData.minSelfEmployerPay || regionData.hourlyPay
            this.form.maxSelfEmployerPay = regionData.maxSelfEmployerPay || regionData.selfEmployerPay
          }
        }
      }
    },
  },
  created () {
    this.shiftHours = `${this.getRelayMinDuration}:00`
    this.minRelayDuration = this.getRelayMinDuration
    this.maxRelayDuration = this.getRelayMaxDuration
    this.minBreakDuration = this.getLunchMinValue
    this.maxBreakDuration = this.getLunchMaxValue
    this.minStartDateInterval = this.getMinStartDateInterval
    this.setDates()

    // const legalFields = [
    //   {
    //     text: '', valid: false,
    //     validator: (value) => {
    //       return (
    //         parseFloat(value) >= parseFloat(this.form.minPay) &&
    //         parseFloat(value) <= parseFloat(this.form.maxPay)
    //       )
    //     }
    //   },
    //   { text: '', valid: false, validator: isNonNull },
    // ]
    // const selfEmploymentFields = [
    //   {
    //     text: '', valid: false,
    //     validator: (value) => {
    //       return (
    //         parseFloat(value) >= parseFloat(this.form.minSelfEmployerPay) &&
    //         parseFloat(value) <= parseFloat(this.form.maxSelfEmployerPay)
    //       )
    //     }
    //   },
    //   { text: '', valid: false, validator: isNonNull },
    // ]
    // const temperatureFields = [
    //   { text: '', valid: true, validator: isNonNull }
    // ]
    // const vacancySelected = [
    //   { text: '', valid: false, validator: isNonNull }
    // ]
    //
    // if (this.hasWorkerType) {
    //   this.validationFields = {
    //     vacancySelected,
    //     temperatureFields,
    //   }
    // } else {
    //   this.validationFields = {
    //     vacancySelected,
    //     temperatureFields,
    //     legalFields,
    //     selfEmploymentFields,
    //   }
    // }
  },
  methods: {
    mapShiftDurationInMinutes (val) {
      if (!val) {
        return 0
      }
      const [hoursString, minutesString] = val.split(':')
      const hours = hoursString ? parseInt(hoursString, 10) : 0
      const minutes = minutesString ? parseInt(minutesString, 10) : 0
      let durationInMinutes = 0
      if (hours > 0) {
        durationInMinutes = minutes + (hours * 60)
      }
      return durationInMinutes
    },
    onRefillOffer () {
      this.$emit('close')
      this.$emit('addBalance')
    },
    changeCountEmpLoyee (value) {
      if ((value === -1 && this.form.laborerNumber > 1) || (value === 1)) {
        this.form.laborerNumber += value
      }
    },

    submitForm () {
      const officeId = this.$store.state.office.userOffice.id
      const {
        vacancySelected,
        temperature,
        hasTransportation,
        freeFood,
        laborerNumber,
        selfEmployerCostPerHour,
        costPerHour,
        gender,
      } = this.form

      const weatherCondition = this.$store.getters['temperature/findTemperatureByName'](temperature)

      // даты начала
      const localeFromDate = this.$moment(this.startPeriod)
      localeFromDate.set({
        hour: parseInt(this.$moment(this.startDate).hour()),
        minute: parseInt(this.$moment(this.startDate).minutes()),
        second: 0,
        millisecond: 0
      })

      const fromDate = localeFromDate.clone().utc()
      const offerStartDate = fromDate.utc()
      const startPeriod = this.isPeriod ? fromDate.clone() : fromDate

      const [hours, minutes] = this.shiftHours.split(':')
      const lunchBreakMinutes = this.lunchBreak ? parseInt(this.lunchBreak) : 0

      const toDate = localeFromDate.clone()
        .add(parseInt(hours), 'hours')
        .add(parseInt(minutes), 'minutes')

      const offerLocaleEndDate = toDate.add(lunchBreakMinutes, 'minutes')
      const offerEndDate = offerLocaleEndDate.clone().utc()

      let localeEndPeriod

      // const localeEndPeriod = this.isPeriod ? this.$moment(this.endPeriod).set({
      //   hour: localeFromDate.hour(),
      //   minute: localeFromDate.minutes(),
      //   second: 0,
      //   millisecond: 0
      // }) : toDate

      if (this.isPeriod) {
        localeEndPeriod = this.$moment(this.endPeriod).set({
          hour: localeFromDate.hour(),
          minute: localeFromDate.minutes(),
          second: 0,
          millisecond: 0
        }).add(parseInt(hours), 'hours')
          .add(parseInt(minutes + lunchBreakMinutes), 'minutes')
      } else {
        localeEndPeriod = toDate
      }

      const endPeriod = localeEndPeriod.clone().utc()
      const vaccine = this.vaccineRequirement.value

      // console.log(startPeriod.format(this.localFormat), 'UTC startPeriod')
      // console.log(localeFromDate.format(this.localFormat), 'locale startPeriod')
      // console.log(localeEndPeriod.format(this.localFormat), 'locale localeEndPeriod')
      // console.log(offerStartDate.format(this.localFormat), 'utc offerStartDate')
      // console.log(endPeriod.format(this.localFormat), 'utc endPeriod')
      // console.log(offerEndDate.format(this.localFormat), 'utc offerEndDate')
      // console.log(offerLocaleEndDate.format(this.localFormat), 'offerLocaleEndDate')

      const localeStartDate = localeFromDate.format(this.localFormat)
      const localeEndDate = offerLocaleEndDate.format(this.localFormat)


      try {
        const minStart = this.$moment().add(10, 'minutes')
        const checkStartTime = this.$moment().utc().isSame(startPeriod, 'day')
        const payloadData = {
          'requiredVaccination': (vaccine && vaccine === REQUIRED_QR_CODE_ID),
          'vacancy': { id: vacancySelected.id },
          'isMulti': (this.isPeriod),
          'executorAssignmentType': this.form.executorAssignmentType,
          'startDate': offerStartDate.format(this.localFormat),
          'endDate': offerEndDate.format(this.localFormat),
          'localeStartDate': localeStartDate,
          'localeEndDate': localeEndDate,
          'targetGender': gender === '-1' ? null : gender,
          'duration': this.shiftHoursFloat,
          'weatherCondition': { id: weatherCondition.id, title: weatherCondition.title },
          hasTransportation, freeFood,
          laborerNumber,
          'costPerHour': parseInt(costPerHour),
          'break': { duration: this.lunchBreak },
          'office': { id: officeId },
          'startPeriod': startPeriod.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          }).format(this.localFormat),
          'workDays': this.getWorkDays,
          'endPeriod': endPeriod.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          }).format(this.localFormat),
          'selfEmployerCostPerHour': parseFloat(`${selfEmployerCostPerHour}`),
        }

        if (!checkStartTime || (checkStartTime && localeFromDate.isAfter(minStart))) {
          this.isLoading = true
          this.$store.dispatch('offer/fetchOffersCreate', payloadData)
            .then(() => {
              this.isLoading = false
              this.$emit('buyOffer')
              this.$emit('close')
            }).catch(() => {
              this.isLoading = false
              this.errorDisplayed = true
              setTimeout(() => {
                this.errorDisplayed = false
              }, 10000)
            })
        } else {
          this.showLocalError = true
          setTimeout(() => {
            this.showLocalError = false
          }, 10000)
        }
      } catch (e) {
        this.isLoading = false
      }
    },

    updateBreakValue (shiftDuration) {
      if (this.hasLunchBreakSettingMatrix) {
        const getBreakValue = (duration) => {
          const matrix = this.getLunchBreakSetting

          let prevVal = null
          // eslint-disable-next-line guard-for-in
          for (const ind in matrix) {
            const value = matrix[ind]
            const key = parseFloat(ind)

            if (duration < key && prevVal === null) {
              return 0
            }
            if (duration === key) {
              return value
            } else if (key > duration) {
              return prevVal
            }
            prevVal = value
          }

          // когда только одно значение
          if (prevVal && duration > prevVal) {
            return prevVal
          }
          return 0
        }
        const duration = this.mapShiftDurationInMinutes(shiftDuration)
        const breakDuration = getBreakValue(duration)
        this.lunchBreak = breakDuration
      }
    },

    onDateChange (nextStartDate) {
      this.shiftHours = `${this.getRelayMinDuration}:00`
      this.setDates(nextStartDate)
    },
    setDates (val, period) {
      const isSameDay = !val || (val && this.$moment().isSame(val, 'day'))
      if (period === false || period === true) {
        this.lunchBreak = ''
      }
      if (!isSameDay || period === true) {
        this.shiftHours = `${this.getRelayMinDuration}:00`
      }

      if (val && period) {
        // const now = this.$moment()
        const now = val
        now
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          // .add(1, 'days')
        this.minStartDate = now
        this.startDate = now.toISOString()
      } else if (period) {
        const now = this.$moment()
        now
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .add(1, 'days')
        this.minStartDate = now
        this.startDate = now.toISOString()
      } else {
        this.minStartDate = this.$moment().add(this.minStartDateInterval, 'minutes')
        const minutes = parseInt(this.minStartDate.format('mm'), 10)
        const hours = parseInt(this.minStartDate.format('HH'), 10)

        let nextMinutes = minutes
        let nextHours = hours

        if (isSameDay) {
          if (minutes >= 45 && minutes <= 59) {
            nextMinutes = 45
          } else if (minutes >= 30 && minutes < 45) {
            nextMinutes = 30
          } else if (minutes >= 15 && minutes < 30) {
            nextMinutes = 15
          } else {
            nextMinutes = 15
          }
        } else {
          nextMinutes = 0
          nextHours = 0
        }

        this.minStartDate
          .set({ hour: nextHours, minutes: nextMinutes, second: 0, millisecond: 0 })

        this.startDate = this.minStartDate.toISOString()
      }
      this.endDate = this.$moment(this.startDate).add(this.shiftHoursFloat, 'hours').toISOString()
      this.updateBreakValue(this.shiftHours)
    },
  }
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/create-offer-modal'
</style>

<style lang="sass">
.selector
  .input-date-wide
    width: 45px
</style>

