const DATE_FORMAT = {
  long: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентярбрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  short: [
    'Янв.',
    'Фев.',
    'Мар.',
    'Апр',
    'Май',
    'Июн',
    'Июл',
    'Авг',
    'Сен',
    'Окт',
    'Ноя',
    'Дек',
  ],
}

const dateFormatApi = (dateStr, timeStr) => {
  const dateObj = dateStr.split('.').reduce((result, obj, index) => {
    if (index === 0) {
      result.day = parseInt(obj)
    } else if (index === 1) {
      result.month = parseInt(obj)
    } else if (index === 2) {
      result.year = parseInt(obj)
    }
    return result
  }, {})

  if (timeStr) {
    const hour = timeStr.substring(0, 2)
    const minute = timeStr.substring(3)
    dateObj.hour = parseInt(hour)
    dateObj.minute = parseInt(minute)
  }

  return new Date(dateObj.year, dateObj.month - 1, dateObj.day,
    dateObj.hour ? dateObj.hour : 0, dateObj.minute ? dateObj.minute : 0)
}

const msToTime = (duration, {
  hourShow = false,
  minutesShow = false,
  secondsShow = false, millisecondsShow = false
}) => {
  if (!duration) return ''
  let strResult = ''
  if (hourShow) {
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

    // hours = (hours < 10) ? '0' + hours : hours
    strResult += hours
  }

  if (minutesShow) {
    let minutes = Math.floor((duration / (1000 * 60)) % 60)

    minutes = (minutes < 10) ? '0' + minutes : minutes
    strResult += `${hourShow ? ':' : ''}${minutes}`
  }

  if (secondsShow) {
    let seconds = Math.floor((duration / 1000) % 60)

    seconds = (seconds < 10) ? '0' + seconds : seconds
    strResult += `${minutesShow ? ':' : ''}${seconds}`
  }


  return strResult
}

const localFormattedDate = () => {
  const delayed = new Date()
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
  const formattedLocaleDate = delayed.toLocaleDateString('ru-RU', options)
    .replace(/[,]/g, '')
    .split(' ')

  const [weekDay, day, month, year] = formattedLocaleDate
  return {
    day,
    month,
    year,
    weekDay,
    hour: delayed.getHours(),
    minutes: delayed.getMinutes(),
  }
}

export { dateFormatApi, msToTime, DATE_FORMAT, localFormattedDate }
